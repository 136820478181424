/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "G-P9MS58467C",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Ronald",
  logo_name: "",
  nickname: "Ronuz / RonaldNolascoU",
  full_name: "Ronald Nolasco",
  subTitle: "Full Stack Developer, Open Source Enthusiast 🔥. Always learning.",
  resumeLink:
    "https://drive.google.com/file/d/1KYkI7MzMBjijb7SbJMkGA_ll2jBzar4i/view?usp=sharing",
  mail: "mailto:ronaldnolasco15@gmail.com",
};

const socialMediaLinks = {
  github: "https://github.com/RonaldNolascoU",
  linkedin: "https://www.linkedin.com/in/ronaldnolasco/",
  gmail: "ronaldnolasco15@gmail.com",
  twitter: "https://twitter.com/RNolasco_U",
  instagram: "https://www.instagram.com/ronaldnolasco17/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications",
        "⚡ Building responsive websites",
        "⚡ Develop mobile applications",
        "⚡ Creating application backend",
        "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Laravel",
          fontAwesomeClassname: "simple-icons:laravel",
          style: {
            color: "#FF2D20",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#DD0031",
          },
        },
        {
          skillName: "Vue",
          fontAwesomeClassname: "simple-icons:vuejs",
          style: {
            color: "#4FC08D",
          },
        },
        {
          skillName: "Nuxt",
          fontAwesomeClassname: "simple-icons:nuxt-dot-js",
          style: {
            color: "#00C58E",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        // {
        //   skillName: "Visual Basic",
        //   fontAwesomeClassname: "simple-icons:dot-net",
        //   style: {
        //     color: "#029FCE",
        //   },
        // },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Don Bosco University",
      subtitle: "Information Technology Engineering",
      logo_path: "udb.png",
      alt_name: "UDB",
      duration: "2019 - 2022",
    },
    {
      title: "Don Bosco University",
      subtitle: "Bachelor in Information Technology",
      logo_path: "udb.png",
      alt_name: "UDB",
      duration: "2017 - 2019",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've completed one internship. I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Full Stack Developer",
          company: "Trez Labs",
          company_url: "https://staging.trezlabs.com",
          logo_path: "trezlabs.jpeg",
          duration: "Dec 2019 - Present",
          location: "Office",
          description:
            "API Integration, QA, Testing, UI, App Development, DevOps",
          skills: [
            "Laravel",
            "JavaScript",
            "Angular",
            "Vue",
            "React",
            "React Native",
            "Ionic",
            "Git",
            "Cloud Computing",
          ],
          color: "#fff",
        },
        {
          title: "Full Stack Developer (Freelancer)",
          company: "Magic Markers",
          company_url: "https://www.magicmarkers.tv/",
          logo_path: "magic.png",
          duration: "May 2021",
          location: "Work From Home",
          description:
            "Created website with authentication module and headless CMS",
          skills: ["Nuxt", "Vue", "Firebase", "Sanity.io"],
          color: "#fff",
        },
        {
          title: "Backend Developer (Freelancer)",
          company: "To Go",
          company_url: "",
          logo_path: "togo.jpeg",
          duration: "May 2021 - June 2021",
          location: "Work From Home",
          description:
            "Collaborated on Backend development with push notifications, GPS tracking",
          skills: ["Laravel", "Voyager", "Pusher", "Payment Gateway"],
          color: "#fff",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Web Developer",
          company: "UDB",
          company_url: "http://www.udb.edu.sv/udb/",
          logo_path: "udb.png",
          duration: "May 2019 - July 2019",
          location: "Office",
          description:
            "Created some pages for new virtual classes on the college",
          color: "#4285F4",
        },
        {
          title: "GitHub Student Developer",
          company: "GitHub",
          company_url: "https://github.com/",
          logo_path: "github.png",
          duration: "Nov 2019 - Present",
          location: "Work from Home",
          description:
            "Contribute to Open Source Community and Open Source Project.",
          color: "#040f26",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is to create Laravel Backend Projects, and Angular, Vue, React Projects. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile-pic.jpeg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "https://twitter.com/Harikrushn9",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "ToDoList",
      url: "https://github.com/RonaldNolascoU/ToDoList",
      description: "To do list",
      languages: [
        {
          name: "Angular",
          iconifyClass: "logos-angular",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "1",
      name: "Laravel/React Twitter Clone",
      url: "https://github.com/RonaldNolascoU/Laravel-GraphQL",
      description:
        "A React Twitter Clone UI with basic functionality such as make a Tweet.Embedded Profile Tweets.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
      ],
    },
    {
      id: "2",
      name: "MERN",
      url: "https://github.com/RonaldNolascoU/MERN",
      description: "E-Commerce using MERN stack.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Node",
          iconifyClass: "logos-nodejs",
        },
      ],
    },
    {
      id: "3",
      name: "Amazon React Clone",
      url: "https://github.com/RonaldNolascoU/ReBuy",
      description: "Amazon Clone using React",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      id: "4",
      name: "Portfolio",
      url: "https://github.com/RonaldNolascoU/RonaldNolascoU.github.io",
      description:
        "Personal portfolio website that showcases my work and experience",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      id: "3",
      name: "v-datatable",
      url: "https://github.com/RonaldNolascoU/v-datatable",
      description: "Simple expand/contract datatable",
      languages: [
        {
          name: "Vue",
          iconifyClass: "logos-vue",
        },
      ],
    },
    {
      id: "4",
      name: "Vue-Datatable",
      url: "https://github.com/RonaldNolascoU/Vue-Datatable",
      description: "Simple expand/contract datatable using PrimeVue",
      languages: [
        {
          name: "Vue",
          iconifyClass: "logos-vue",
        },
      ],
    },
    {
      id: "5",
      name: "react-store",
      url: "https://github.com/RonaldNolascoU/react-store",
      description: "React store with global state management.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      id: "6",
      name: "laravel-sms-verification",
      url: "https://github.com/RonaldNolascoU/laravel-sms-verification",
      description: "OTP verification library",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "AWS",
          iconifyClass: "logos-aws",
        },
      ],
    },
    {
      id: "7",
      name: "React Modal",
      url: "https://github.com/RonaldNolascoU/Modal",
      description: "React modal with parent/child components.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      id: "8",
      name: "Covid Tracker",
      url: "https://github.com/RonaldNolascoU/Covid-Tracker",
      description: "Covid Tracker table",
      languages: [
        {
          name: "Angular",
          iconifyClass: "logos-angular",
        },
      ],
    },
    {
      id: "9",
      name: "IonBuy",
      url: "https://github.com/RonaldNolascoU/IonBuy",
      description: "E-commerce mobile application",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Ionic",
          iconifyClass: "logos-ionic",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "10",
      name: "RegexJS",
      url: "https://github.com/RonaldNolascoU/RegexJS",
      description: "Simple page with regular expression examples.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
      ],
    },
    {
      id: "11",
      name: "npx-fb-dropdown",
      url: "https://github.com/RonaldNolascoU/npx-fb-dropdown",
      description: "Facebook settings dropdown",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      id: "11",
      name: "ReactBadges",
      url: "https://github.com/RonaldNolascoU/ReactBadges",
      description: "Conference management website",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      id: "11",
      name: "Angular",
      url: "https://github.com/RonaldNolascoU/Angular",
      description: "Foursquare clone",
      languages: [
        {
          name: "Angular",
          iconifyClass: "logos-angular",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
